<template>
    <div class="guest-header-container">
        <div class="guest-header">
            <div class="img-wrapper">
                <img :src="require('@/assets/img/logo.png')" alt="logo">
            </div>

            <div class="link-container">
                <a @click="$emit('move', 'home')">{{ $t('home') }}</a>
                <a @click="$emit('move', 'about')" >{{ $t('about_us') }}</a>
                <a @click="$emit('move', 'hiw')" >{{ $t('how_it_works') }}</a>
                <a @click="$emit('move', 'contact')" >{{ $t('contact_us') }}</a>
            </div>
    
            <div class="button-container">
                <div class="select language">
                    <select
                        v-model="language"
                        @change="handleSetActiveLanguage($event)"
                    >
                        <option
                            :value="language.id"
                            v-for="language in languages"
                            :key="language.id"
                        >
                            {{ language.lang }}
                        </option>
                    </select>
                </div>

                <div class="menu-icon-wrapper" @click="showMenu = !showMenu">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>

                <div class="guest-button">
                    <button @click="$router.push('/sign-in').catch(()=>{})" class="sign-in">{{ $t('sign_in') }}</button>
                    <button @click="$router.push('/sign-up').catch(()=>{})" class="get-started">{{ $t('get_started') }}</button>
                </div>
            </div>
        </div>

        <div class="mobile-link-container" v-if="showMenu">
            <a @click="goTo('home')">{{ $t('home') }}</a>
            <a @click="goTo('about')" >{{ $t('about_us') }}</a>
            <a @click="goTo('hiw')" >{{ $t('how_it_works') }}</a>
            <a @click="goTo('contact')" >{{ $t('contact_us') }}</a>
            <button @click="signIn" class="sign-in">{{ $t('sign_in') }}</button>
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie'
import { mapFields } from 'vuex-map-fields'

export default {
    props: {
        isLoggedIn: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            languages: [
                { lang: "English", id: "en" },
                { lang: "Chinese", id: "cn" }
            ],

            showMenu: false
        }
    },

    computed: {
        ...mapFields(['language'])
    },

    methods: {
        handleSetActiveLanguage (val) {
            let lang = val.target.value

            this.$i18n.locale = lang
			Cookies.set('locale', lang)
        },

        goTo (path) {
            this.showMenu = false
            this.$emit('move', path)
        },

        signIn () {
            this.showMenu = false
            this.$router.push('/sign-in').catch(()=>{})
        }
    },
}
</script>

<style lang="scss" scoped>
.guest-header-container {
    padding: 10px;
    position: relative;

    .guest-header {
        max-width: 1200px;
        display: flex;
        margin: auto;
        align-items: center;
        justify-content: space-between;

        .img-wrapper {
            img {
                cursor: pointer;
                width: 100px;
                height: 55px;
            }
        }
    
        .link-container {
            display: none;
            justify-content: center;
            
            @include desktop {
                display: flex;
            }
    
            a {
                cursor: pointer;
                font-weight: 600;
                color: #221E1F;
                margin-right: 22px;

                @include desktop {
                    margin-right: 40px;
                }
    
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    
        .button-container {
            // flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            .select {
                display: flex;
                color: #221e1f;
                font-weight: 600;
                display: flex;
                margin-right: 15px;

                &.language {
                    margin-right: 15px;

                    @include desktop {
                        margin-right: 35px;
                    }
                }

                p {
                    font-family: 'Crimson Pro', sans-serif;
                    font-size: 18px;
                }

                select {
                    cursor: pointer;
                    border: transparent;
                    background-color: transparent;
                    font-family: 'Crimson Pro', sans-serif;
                    font-size: 18px;

                    option {
                        font-size: 14px;
                    }
                }
            }

            .guest-button {
                display: none;

                @include desktop {
                    display: flex;
                }
            }

            .menu-icon-wrapper {
                cursor: pointer;

                @include desktop {
                    display: none;
                }
                
                .line {
                    width: 20px;
                    height: 2px;
                    background-color: black;
                    margin: 4px 0;
                }
            }
    
            button {
                font-weight: 600;
                font-size: 18px;
                height: 45px;
                border: 1px solid #00AEEF;
    
                &.sign-in {
                    background-color: #ffffff;
                    color: #00AEEF;
                    margin-right: 15px;
                }
    
                &.get-started, &.logout {
                    background-color: #00AEEF;
                    color: #ffffff;
                }
    
                &.logout {
                    width: 124px;
                }
            }
        }
    }

    .mobile-link-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #ffffff;
        left: 0;
        padding: 10px 0 20px;
        text-align: center;

        a {
            cursor: pointer;
            font-weight: 600;
            color: #221E1F;
            margin-bottom: 20px;
        }

        .sign-in {
            font-weight: 600;
            font-size: 18px;
            height: 42px;
            border: 1px solid #00AEEF;
            width: 125px;
            background-color: #00AEEF;
            color: #ffffff;
            margin: auto;
        }
    }
}
</style>